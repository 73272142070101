export default {

  data () {
    return {
      imgUrlLogo: require('@/assets/images/logo.png'),
      imgUrl300x200: require('@/assets/images/300x200.png'),
      imgUrlWx: require('@/assets/images/wx.jpg'),
      imgUrlNews: require('@/assets/images/news.jpg'),
      imgUrlWechat: require('@/assets/images/wechat.png'),
      imgUrlAlipay: require('@/assets/images/alipay.png'),
      imgUrlBalance: require('@/assets/images/balance.png')
    }
  }

}
