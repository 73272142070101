import router from './router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'

NProgress.configure({ showSpinner: false })

// 需要登录才能访问的路由
const blackList = [
  '/personalCenter/personalCenter',
  '/personalCenter/myaddon',
  '/personalCenter/recharge',
  '/personalCenter/profile'
]

router.beforeEach((to, from, next) => {
  NProgress.start()

  if (getToken()) {
    // 有token
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      next()
    }
  } else {
    // 没有token
    if (blackList.indexOf(to.path) !== -1) {
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    } else {
      next()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
