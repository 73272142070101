
<script>
export default {
  name: 'popup',
  props: {
    config: {
      type: Object,
      default () {
        return {}
      },
      required: true
    },
    hasFoot: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      baseConfig: {
        visible: false
      },
      contentFn: undefined,
      confirmFn: undefined,
      cancelFn: undefined,
      isLoading: false
    }
  },
  methods: {
    close () {
      if (typeof this.cancelFn === 'function') {
        this.cancelFn()
      }
      this.baseConfig.visible = false
    },
    content (content) {
      if (typeof content === 'function') {
        this.contentFn = content
      }
      return this
    },
    confirm (fn) {
      if (typeof fn === 'function') {
        this.confirmFn = fn
      }
      return this
    },
    cancel (fn) {
      if (typeof fn === 'function') {
        this.cancelFn = fn
      }
      return this
    }
  },
  created () {
    this.baseConfig = Object.assign(this.baseConfig, this.config)
  },
  render: function (h) {
    const _this = this
    return h(
      'el-dialog',
      {
        props: { ...this.baseConfig },
        on: {
          close: function () {
            _this.close()
            document.body.removeChild(this.$el)
          }
        }
      },
      (function () {
        const foot = h('span', {
          slot: 'footer',
          class: 'dialog-footer'
        }, [
          h('el-button', {
            on: {
              click: () => {
                _this.close()
              }
            }
          }, '取消'),
          h('el-button', {
            attrs: {
              type: 'primary'
            },
            props: {
              loading: _this.isLoading
            },
            on: {
              click: () => {
                let flag = true
                if (typeof _this.confirmFn === 'function') {
                  flag = _this.confirmFn()
                }
                if (isPromise(flag)) {
                  _this.isLoading = true
                  flag.then(res => {
                    if (res !== false) {
                      res = true
                    }
                    if (res === true) {
                      _this.close()
                    }
                  }).finally(() => {
                    _this.isLoading = false
                  })
                } else {
                  if (flag !== false) {
                    flag = true
                  }
                  if (flag) {
                    _this.close()
                  }
                }
              }
            }
          }, '确定')
        ])

        if (typeof _this.contentFn === 'function') {
          var result = _this.contentFn(h)
          if (Array.isArray(result)) {
            return [...result, _this.hasFoot ? foot : '']
          } else {
            return [result, _this.hasFoot ? foot : '']
          }
        }
      })()
    )
  }
}

function isPromise (obj) {
  return !!obj && (typeof obj === 'object' || typeof obj === 'function') && typeof obj.then === 'function' && typeof obj.catch === 'function'
}
</script>
