import _ from 'lodash'
import dayjs from 'dayjs'

// 分转元
export function fenToYuan (value) {
  value = _.toNumber(value)
  return _.round(value / 100, 2)
}

// 日期格式化
export function dateFormat (value, format = 'YYYY-MM-DD') {
  return dayjs(value).format(format)
}

// 判断是否则日期之前
export function isBefore (dateValue) {
  return dayjs().isBefore(dayjs(dateValue))
}
