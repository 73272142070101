<template>
  <div style="margin-top:60px;">
    <el-container>
      <el-aside width="200px">
        <el-menu class="el-menu-vertical"
                 router
                 :default-active="$route.path">

          <el-menu-item index="/personalCenter/personalCenter">
            <i class="el-icon-user"></i>
            <span slot="title">会员中心</span>
          </el-menu-item>
          <el-menu-item index="/personalCenter/myaddon">
            <i class="el-icon-box"></i>
            <span slot="title">我购买的插件</span>
          </el-menu-item>
          <!-- <el-menu-item index="/personalCenter/recharge">
            <i class="el-icon-bank-card"></i>
            <span slot="title">充值余额</span>
          </el-menu-item> -->
          <el-menu-item index="/personalCenter/profile">
            <i class="el-icon-setting"></i>
            <span slot="title">账户设置</span>
          </el-menu-item>
        </el-menu>

      </el-aside>
      <el-main>
        <app-main />
      </el-main>
    </el-container>

  </div>

</template>

<script>
import AppMain from './components/AppMain.vue'
export default {
  name: 'layoutPersonalCenter',
  components: {
    AppMain
  }
}
</script>

<style scoped>
.el-menu-vertical {
  height: 100%;
}
</style>
