import Vue from 'vue'
import VueRouter from 'vue-router'

import Layout from '@/layout/index'
import PersonalCenterLayout from '@/layout/personalCenter'

const original = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return original.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    name: 'home',
    component: Layout,
    redirect: 'index',
    children: [
      {
        path: 'index',
        component: (resolve) => require(['@/views/Home'], resolve)
      }
    ]
  },
  {
    path: '/login',
    component: Layout,
    children: [
      {
        path: '/',
        component: (resolve) => require(['@/views/login'], resolve)

      }
    ]
  },
  {
    path: '/forgetPassword',
    component: Layout,
    children: [
      {
        path: '/',
        component: (resolve) => require(['@/views/ForgetPassword'], resolve)
      }
    ]
  },
  {
    path: '/showModule',
    component: Layout,
    children: [
      {
        path: '/',
        component: (resolve) => require(['@/views/ShowModule'], resolve)
      }
    ]
  },
  {
    path: '/personalCenter',
    component: Layout,
    children: [
      {
        path: 'personalCenter', // 会员中心
        component: PersonalCenterLayout,
        children: [
          {
            path: '/',
            component: (resolve) => require(['@/views/PersonalCenter/PersonalCenter'], resolve)
          }
        ]
      },
      {
        path: 'myaddon', // 我购买的插件
        component: PersonalCenterLayout,
        children: [
          {
            path: '/',
            component: (resolve) => require(['@/views/PersonalCenter/MyAddon'], resolve)
          }
        ]
      },
      {
        path: 'profile', // 账户设置
        component: PersonalCenterLayout,
        children: [
          {
            path: '/',
            component: (resolve) => require(['@/views/PersonalCenter/Profile'], resolve)
          }
        ]
      },
      {
        path: 'recharge', // 充值余额
        component: PersonalCenterLayout,
        children: [
          {
            path: '/',
            component: (resolve) => require(['@/views/PersonalCenter/Recharge'], resolve)
          }
        ]
      },
      {
        path: 'editPassword', // 修改密码
        component: PersonalCenterLayout,
        children: [
          {
            path: '/',
            component: (resolve) => require(['@/views/PersonalCenter/EditPassword'], resolve)
          }
        ]
      }
    ]
  }

]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
