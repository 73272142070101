import { login, logout } from '@/api/login'
import { getToken, setToken, removeToken, getUserInfo, setUserInfo, removeUserInfo } from '@/utils/auth'

const user = {
  state: {
    token: getToken(),
    userInfo: getUserInfo()
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo
    }
  },
  actions: {
    // 自动登录
    AutoLogin ({ commit }, { token, userInfo }) {
      setToken(token)
      setUserInfo(userInfo)
      commit('SET_TOKEN', token)
      commit('SET_USERINFO', userInfo)
    },
    // 登录
    Login ({ commit }, userInfo) {
      const username = userInfo.username.trim()
      const password = userInfo.password
      const code = userInfo.code
      const uuid = userInfo.uuid
      return new Promise((resolve, reject) => {
        login(username, password, code, uuid).then(res => {
          const { code, msg, data } = res
          if (code === 0) {
            setToken(data.token)
            setUserInfo(data.userInfo)
            commit('SET_TOKEN', data.token)
            commit('SET_USERINFO', data.userInfo)
            resolve()
          } else {
            reject(msg || '登录失败!!')
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 退出系统
    LogOut ({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout().then(() => {
          commit('SET_TOKEN', '')
          commit('SET_USERINFO', '')
          removeToken()
          removeUserInfo()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 前端 登出
    FedLogOut ({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_USERINFO', '')
        removeToken()
        removeUserInfo()
        resolve()
      })
    }
  }
}
export default user
