import Cookies from 'js-cookie'

const TokenKey = 'CodeStoreAdminToken'
const UserInfoKey = 'CodeStoreAdminUserInfoKey'

export function getToken () {
  return Cookies.get(TokenKey)
}

export function setToken (token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken () {
  return Cookies.remove(TokenKey)
}

export function getUserInfo () {
  const result = Cookies.get(UserInfoKey)
  return result ? JSON.parse(result) : {}
}

export function setUserInfo (data) {
  const user = data ? JSON.stringify(data) : ''
  return Cookies.set(UserInfoKey, user)
}

export function removeUserInfo () {
  return Cookies.remove(UserInfoKey)
}
