import request from '@/utils/request'

// 登录方法
export function login (username, password, code, uuid) {
  const data = {
    username,
    password,
    verifyCode: code,
    verifyKey: uuid
  }
  return request({
    url: '/codeStoreFrontAdmin/login',
    method: 'post',
    data: data
  })
}

// 退出方法
export function logout () {
  return request({
    url: '/codeStoreFrontAdmin/logout',
    method: 'post'
  })
}

// 注册
export function register (data) {
  return request({
    url: '/codeStoreFront/register',
    method: 'post',
    data: data
  })
}

// 获取验证码
export function getCodeImg () {
  return request({
    url: '/captcha/get',
    method: 'get'
  })
}

// 发送邮箱验证码
export function sendCaptchaByEmail (email) {
  return request({
    url: '/codeStoreFront/sendMail',
    method: 'get',
    params: {
      email
    }
  })
}

// 通过邮箱修改密码
export function resetPasswordByEmail (data) {
  return request({
    url: '/codeStoreFront/resetPasswordByEmail',
    method: 'post',
    data: data
  })
}

// 发送手机验证码
export function sendCaptchaByMobile (mobile) {
  return request({
    url: '/codeStoreFront/captchaByMobile',
    method: 'get',
    params: {
      mobile
    }
  })
}

// 通过手机修改密码
export function resetPasswordByMobile (data) {
  return request({
    url: '/codeStoreFront/resetPasswordByMobile',
    method: 'post',
    data: data
  })
}

// 发送注册用手机验证码
export function registerVerifyCode (data) {
  return request({
    url: '/codeStoreFront/registerVerifyCode',
    method: 'post',
    data: data
  })
}

// 获取微信登录二维码
export function createQrcode () {
  return request({
    url: '/codeStoreFront/createQrcode',
    method: 'get'
  })
}

// 绑定账号
export function bind (data) {
  return request({
    url: '/codeStoreFront/bind',
    method: 'post',
    data: data
  })
}

// 注册并绑定
export function registerBind (data) {
  return request({
    url: '/codeStoreFront/registerBind',
    method: 'post',
    data: data
  })
}
